* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

.container2 {
  margin: 0;
  height: 92.3vh;
  overflow-y: auto; 
  /*overflow-y: auto*/
  /*background: linear-gradient(to bottom right, #FFFFFF, #FFAE42);*/
  display: block;  /* Add this to override the default flex behavior*/
  scrollbar-width: none;
}

.container2::-webkit-scrollbar {
  width: 16px;
}

.container2  {
  background-color: transparent;
}

.container2::-webkit-scrollbar-thumb {
  background-color: rgba(205, 102, 0, 0.5);
  border-radius: 20px;
  border: 4px solid transparent;
  background-clip: content-box;
}

.container2::-webkit-scrollbar-thumb:hover {
  background-color: rgba(205, 102, 0, 0.8);
}

.container {
    margin: 0;
    min-height: 92.3vh;
    overflow: hidden;
    /*background: linear-gradient(to bottom right, #FFFFFF, #FFAE42);*/
    /*background: linear-gradient(to bottom right, #FFFFFF, #FAD5E5);*/
    /*#CC7029*/
  }
  
  .heading {
    font-size: 3rem;
    font-weight: 800;
    margin-bottom: 2rem;
  }
  
  .balance {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }
  
  .form-control {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
  }
  
  .form-label {
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
  }
  
  .input {
    font-size: 1.5rem;
    width: 300px;
    text-align: right;
  }
  
  .button {
    font-size: 1.5rem;
    font-weight: 600;
    padding: 1.5rem 3rem;
    border-radius: 50px;
    background-color: #007AFF;
    color: #FFFFFF;
    border: none;
    margin-bottom: 1rem;
    margin-left: 1rem;
  }
  
  .button:active {
    transform: translateY(5px);
  }
  
  .connect-button-container {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
  
  .connect-button-container > button {
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0.6rem 1.2rem;
    border-radius: 50px;
    background-color: #007AFF;
    color: #FFFFFF;
    border: none;
    margin-bottom: 1rem;
  }
  
  .connect-button-container > button:active {
    transform: translateY(5px);
  }
  
  .disconnect-button-container {
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  .wallet-address-button {
    display: inline-block;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0.6rem 1.2rem;
    background-color: transparent;
    color: #007AFF;
    margin-bottom: 1rem;
    text-align: left;
    border: none;
    box-shadow: none;
  }

  .disconnect-button-container > .disconnect-button {
    display: inline-block;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0.6rem 1.2rem;
    border-radius: 50px;
    background-color: #FFFFFF;
    color: #007AFF;
    border: none;
    margin-bottom: 1rem;
  }
  
  .bold {
    font-weight: bold;
  }